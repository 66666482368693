<template>
  <div>
    <Header />
    <div class="not-found">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4 col-md-6 no-gap">
            <div class="column-1">
              <div class="card mobile text-center">
                <h1>404</h1>
                <p>Halaman yang anda cari tidak ditemukan</p>
                <b-link to="/">
                  <button class="btn btn-visit">Kembali ke Beranda</button>
                </b-link>
              </div>
              <div class="image-1">
                <!-- <img src="@/assets/img/404/column1/column1-ornament.svg" /> -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 no-gap text-left">
            <div class="column-2">
              <div class="card text-center">
                <h1 class="title">404</h1>
                <p class="my-4 description">
                  Halaman yang anda cari tidak ditemukan
                </p>
                <b-link to="/">
                  <button class="btn btn-visit">Kembali ke Beranda</button>
                </b-link>
              </div>
              <div class="image-2 text-center">
                <!-- <img src="@/assets/img/404/column2/column2-ornament.svg" /> -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 no-gap text-right">
            <div class="column-3">
              <div class="image-3">
                <!-- <img src="@/assets/img/404/column3/column3-ornament.svg" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";

export default {
  name: "NotFound",
  metaInfo: {
    title: "404 | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  padding-top: 60px;
}
.column-1 {
  float: left;
  width: 100%;
  height: 100vh;
  // background: url("~@/assets/img/404/column1/column1-bg.svg");
  // background-color: $primaryColor;
  background-color: $tertiaryColor;
  background-position: bottom;

  .mobile {
    display: none;

    h1 {
      font-family: $inter;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;

      text-align: center;
      color: #e60012;
    }

    p {
      font-family: $inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;

      text-align: center;
      color: #1f2937;
    }

    .btn-visit {
      padding: 12px 24px 12px 24px;
      background: #ffd770;
      box-shadow: 0px 0.57656px 1.15312px rgba(107, 114, 128, 0.06),
        0px 0.57656px 1.72968px rgba(107, 114, 128, 0.1);
      border-radius: 50px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;

      text-align: center;
      color: #29064c;
    }
  }

  .image-1 img {
    width: auto;
    height: 100vh;
    background-position: right bottom;
  }
}

.column-2 {
  float: left;
  width: 100%;
  height: 100vh;
  // background: url("~@/assets/img/404/column2/column2-bg.svg");
  background-color: $secondaryColor;
  background-position: bottom;

  h1 {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;

    text-align: center;
    color: #3f0975;
  }

  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    text-align: center;
    color: #1f2937;
  }

  .title {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;

    text-align: center;
    color: #3f0975;
  }

  .description {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;

    text-align: center;
    color: #1f2937;
  }

  .btn-visit {
    padding: 12px 32px 12px 32px;
    background: #ffd770;
    box-shadow: 0px 0.57656px 1.15312px rgba(107, 114, 128, 0.06),
      0px 0.57656px 1.72968px rgba(107, 114, 128, 0.1);
    border-radius: 50px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #29064c;
  }

  .image-2 img {
    width: auto;
    height: 100vh;
    background-position: center top;
  }
}

.column-3 {
  float: left;
  width: 100%;
  height: 100vh;
  // background: url("~@/assets/img/404/column3/column3-bg.svg");
  background-color: $tertiaryColor;
  background-position: bottom;

  .image-3 img {
    width: auto;
    height: 100vh;
    background-position: right bottom;
  }
}

.no-gap {
  padding: 0 !important;
  margin: 0 !important;
}

.card {
  position: absolute;
  padding: 4rem;
  margin: 4rem;
  box-shadow: 0px 11.5312px 14.414px rgba(116, 42, 42, 0.1),
    0px 5.7656px 5.7656px rgba(116, 42, 42, 0.04);
  border-radius: 9.22495px;
}

@media only screen and (max-width: 768px) {
  .column-1 {
    h1 {
      font-size: 22px;
    }

    p {
      font-size: 12px;
    }

    .btn-visit {
      font-size: 12px;
    }
  }

  .column-2 {
    display: none;
  }

  .column-3 {
    display: none;
  }

  .card {
    margin: 2rem;
  }

  .mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 1360px) {
  .column-2 {
    h1 {
      font-size: 22px;
    }

    p {
      font-size: 12px;
    }

    .btn-visit {
      font-size: 12px;
    }
  }

  .card {
    padding: 2rem;
  }
}
</style>